import { connect } from 'react-redux';
import PlayersAdminList from '../components/playersadmin/PlayersAdminList';
import {withRouter} from 'react-router-dom';


const mapStateToProps = (state, ownProps) => ({
    players: state.players,
    id: ownProps.match.params.id,
    ui: state.ui
})

const PlayersAdminListContainer = withRouter(connect(mapStateToProps)(PlayersAdminList));

export default PlayersAdminListContainer;
import React from 'react';

const renderInlineField = ({ input, label, type, meta: { touched, error, invalid, warning } }) => (
    <div className={`form-group ${touched && invalid ? 'has-error' : ''}`}>
        <div className="form-inline">
            <label className="control-label">{label}</label>&nbsp; &nbsp;
            <input {...input} className="form-control" placeholder={label} type={type} />
        </div>
        <div className="help-block">
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>

    </div>
);

export default renderInlineField;
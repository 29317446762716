import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import HomeContainer from './containers/homeContainer';
import { About } from './components/about';
import GameCreate from './components/gamesadmin/GameCreate';
import PlayContainer from './containers/playContainer';
import GamesAdminContainer  from './containers/gamesAdminContainer';
import GameDeleteFormContainer  from './containers/gameDeleteFormContainer';
import NavigationContainer from './containers/navigationContainer';
import GameEdit from './components/gamesadmin/GameEdit';
import PlayerCreate from './components/playersadmin/PlayerCreate';
import PlayersAdminContainer from './containers/playersAdminContainer';
import PlayerDeleteFormContainer  from './containers/playerDeleteFormContainer';
import PlayerEditFormContainer  from './containers/playerEditFormContainer';
import './App.css';

export const App = ({store}) => (
      <BrowserRouter>
        <div>
          <div className="container">
          <NavigationContainer />
          </div>
          <main className="container">
          <Route exact path="/" component={PlayContainer} match="exact" />
          <Route exact path="/games" component={HomeContainer} />
          <Route exact path="/mygames" component={GamesAdminContainer} />
          <Route path="/about" component={About} />
          <Route path="/admin" component={GamesAdminContainer} />
          <Route path="/game/create" component={GameCreate} />
          <Route path="/game/edit/:id" component={GameEdit} />
          <Route path="/game/delete/:id" component={GameDeleteFormContainer} />
          <Route path="/players/edit/:id" component={PlayersAdminContainer} />
          <Route path="/player/create/:id" component={PlayerCreate} />
          <Route path="/player/edit/:id/:playerId" component={PlayerEditFormContainer} />
          <Route path="/player/delete/:id/:playerId" component={PlayerDeleteFormContainer} />
          <Route path="/play/:id?" component={PlayContainer} />
          </main>
        </div>
      </BrowserRouter>
);



export default App;
const LOAD   = 'lalaladders/player/LOAD';
const CREATE = 'lalaladders/player/CREATE';
const UPDATE = 'lalaladders/player/UPDATE';
const REMOVE = 'lalaladders/player/REMOVE';

export const player = (state = {}, action) => {
    switch (action.type) {
        case LOAD:
            return action.player
        default:
            return state
    }
}


// Action Creators
export const load = player => {
    return { type: LOAD, player };
  }
  
  export const create = player => {
    return { type: CREATE, player };
  }
  
  export const update = player => {
    return { type: UPDATE, player };
  }
  
  export const remove = player => {
    return { type: REMOVE, player };
  }
import { connect } from 'react-redux';
import { Play } from '../components/play';
import { receivePlayers, getFixedPlayers } from '../actions';

const mapDispatchToProps = ({
    receivePlayers,
    getFixedPlayers
});

const PlayContainer = connect(null, mapDispatchToProps)(Play);

export default PlayContainer;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReduxThunk from 'redux-thunk';
import { Provider } from 'react-redux'
import firebase from './config/'
import 'firebase/auth'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase';
import { reducer as formReducer } from 'redux-form'
import {games} from './store/gamesReducer';
import {game} from './store/gameReducer';
import {players} from './store/playersReducer';
import {player} from './store/playerReducer';
import {ui} from './store/uiReducer';
import {notifications} from './store/notificationsReducer';



// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
}

// Initialize firebase instance


// Initialize other services on firebase instance
// firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer, 
  games,
  game,
  players,
  player,
  ui,
  notifications,
  form: formReducer
  // firestore: firestoreReducer // <- needed if using firestore
});
// Create store with reducers and initial state
const initialState = {}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	const middlewares = [ReduxThunk];
	const store = createStore(rootReducer, initialState,
		composeEnhancers(
			applyMiddleware(...middlewares))
	)
		;

// const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch
}

ReactDOM.render(<Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class GameAdminLink extends React.Component {

	shouldComponentUpdate(nextProps) {
		return (this.props.name !== nextProps.name);
	}

	render() {

		return (<li className="list-group-item clearfix" >
			<div className="container-fluid">
                    <div className="row vertical-align">
                        <div className="col-xs-4">
                            {this.props.name} - {this.props.description}
                        </div>
                        <div className="col-xs-2">
                            <Link  to={`/play/${this.props.id}`} >
                               play game
                            </Link>
                        </div>
                        <div className="col-xs-2">
                            <Link className='btn btn-default btn-primary' to={`/players/edit/${this.props.id}`} >
                                edit players
                            </Link>
                        </div>
                        <div className="col-xs-2">
                        <Link className='btn btn-default btn-info' to={`/game/edit/${this.props.id}`} >
                                edit game
                            </Link>
                        </div>
                        <div className="col-xs-2">
                            <Link className='btn btn-danger'  to={`/game/delete/${this.props.id}`}> 
                                delete game
                            </Link>
                        </div>
    
                    </div>
                </div>

		</li>
		);
	}
}


GameAdminLink.defaultProps = {
	name: 'unknown',
    description: 'unknown',
    id: '0'
};

GameAdminLink.propTypes = {
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired
};


import React from 'react';
import GameNewFormContainer from '../../containers/gameNewFormContainer';

const GameCreate = () => {
    return (
        <div>
            <h2>Create new game:</h2>
            <GameNewFormContainer />
        </div>
    );
}

export default GameCreate;


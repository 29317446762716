import { connect } from 'react-redux';
import { Home } from '../components/home';
import { getGames } from '../actions';

const mapDispatchToProps = ({
    getGames
});

const mapStateToProps = (state) => ({
    games: state.games,
    ui: state.ui
});

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);

export default HomeContainer;
import { connect } from 'react-redux';
import Navigation from '../components/navigation';

const mapStateToProps = (state) => ({
    user: state.user
})



const navigationContainer = connect(mapStateToProps)(Navigation);


export default navigationContainer;
const LOAD = 'lalaladders/games/LOAD';
const ADD = 'lalaladders/games/ADD';
const UPDATE = 'lalaladders/games/UPDATE';
// const REMOVE = 'lalaladders/games/REMOVE';

export const games = (state = [], action) => {
    switch (action.type) {
        case ADD:
            return [
                action.game, ...state
            ];
        case UPDATE:
            const obj = state.find(x => x.id === action.game.id);
            let index = state.indexOf(obj);
            return [
                ...state.slice(0, index), action.game, ...state.slice(index + 1)
            ];
        case LOAD:
            return action.games;
        default:
            return state
    }
}


// Action Creators
export const load = games => {
    return { type: LOAD, games };
}

export const add = game => {
    return { type: ADD, game };
}

export const update = game => {
    return { type: UPDATE, game };
}

// not required, becasue simply reload
// export const remove = game => {
//     return { type: REMOVE, game };
// }
import { connect } from 'react-redux';
import GameDeleteForm from '../components/forms/gameDeleteForm';
import {withRouter} from 'react-router-dom'
import { deleteGame } from '../actions';


const mapDispatchToProps = ({
    onSubmit: deleteGame
});

const mapStateToProps = (state, ownProps) => ({
    initialValues: state.games.find(x => x.id === ownProps.match.params.id),
    games: state.games
});

const GameDeleteFormContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(GameDeleteForm));


export default GameDeleteFormContainer;
import React from 'react';
import { Link } from 'react-router-dom';

export class PlayersAdminList extends React.Component {

    render() {
        const { players, id, ui } = this.props;
        let p = <div className="alert alert-info">...loading players.</div>;
        if (!ui.loading) {
            if (players && players.length > 0) {
                p = <ul className="list-group">
                    {players.map(function (item, i) {
                        return <li className="list-group-item" key={i}>
                            <div className="container-fluid">
                                <div className="row vertical-align">
                                    <div className="col-xs-2">
                                        <img src={item.image} alt={item.name} title={item.name} />
                                    </div>
                                    <div className="col-xs-2">{item.name}
                                    </div>
                                    <div className="col-xs-2">
                                        <Link className="btn btn-primary" to={`/player/edit/${id}/${item.id}`}>edit</Link>
                                    </div>
                                    <div className="col-xs-6">
                                        <Link className="btn btn-default" to={`/player/delete/${id}/${item.id}`}>delete</Link>
                                    </div>
                                </div>
                            </div>
                        </li>;
                    }
                    )}
                </ul>;
            }
            else {
                p=<div>There are no players in this game.  <Link to={`/player/create/${id}`}> Add a player</Link>.</div>;
            }
        }
        return <div>
            <Link to={`/player/create/${id}`}><span className="glyphicon glyphicon-plus"></span> Add new player to this game</Link>
            <h2>Edit or delete existing player:</h2>
            {p}
        </div>;
    }
}


export default PlayersAdminList;
import { connect } from 'react-redux';
import PlayerForm from '../components/forms/playerForm';
import { withRouter } from 'react-router-dom'
import { addPlayer } from '../actions';
import constants from '../constants';
import {formValueSelector} from  'redux-form';

// const sr = { R: 'Reason for going down a snake' };
// const ss = { S: 'What I say after going down a snake' };
// const hr = { R: 'Reason for going up a ladder' };
// const hs = { S: 'What I say after going up a Ladder' };

const data = {
    name: '',
    image: constants.noImage,
    HR: ['Reason for going up a ladder'],
    HS: ['What I say after going up a Ladder'],
    SR: ['Reason for going down a snake'],
    SS: ['What I say after going down a snake']
}

function dataWithGameId(id, ids) {
    data.gameId = id;
    data.sendEmail = ids.indexOf(id) === -1;
    return data;
}

const mapDispatchToProps = ({
    onSubmit: addPlayer
});

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('player');
    const ids = state.notifications;
    return {
        initialValues: dataWithGameId(ownProps.match.params.id, ids),
        image: selector(state, 'image')
    }
};

const PlayerNewFormContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerForm));


export default PlayerNewFormContainer;
import React from 'react';

const renderStatement = ({ input, label, type, onClick, index, meta: { touched, error, invalid, warning } }) => {
    const btn = index ?
        <button className="btn btn-danger"
            type="button"
            title="Remove Statement"
            value="Delete"
            onClick={onClick}
        >Delete </button> :
        "";

    return <div className={`form-group ${touched && invalid ? 'has-error' : ''}`}>
        <div className="form-inline">
            <label className="sr-only">{label}</label>
            <input {...input} className="form-control" placeholder={label} type={type} style={{ width: '70%' }} />
            &nbsp; &nbsp;
            {btn}
        </div>
        <div className="help-block">
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>

    </div>
};

export default renderStatement;
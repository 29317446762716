import { connect } from 'react-redux';
import GameForm from '../components/forms/gameForm';
import {withRouter} from 'react-router-dom'
import { updateGame } from '../actions';


function dataWithSendEmail(games, id, ids) {
    const data = games.find(x => x.id === id);
    return {
        sendEmail: ids.indexOf(id) === -1,
        id: data.id,
        name: data.name,
        description: data.description
    }
}

const mapDispatchToProps = ({
    onSubmit: updateGame
});

const mapStateToProps = (state, ownProps) => ({
    initialValues: dataWithSendEmail(state.games,  ownProps.match.params.id, state.notifications)
});

const GameEditFormContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(GameForm));


export default GameEditFormContainer;
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GamesEditableContainer from '../../containers/gamesEditableContainer';

export class GamesList extends React.Component {

    componentDidMount() {
       this.props.getMyGames();
    }

    render() {
        return (
            <div>
                <Link to="/game/create">Create new game</Link>           
                <h2>Edit or delete existing game:</h2>
                <p>N.B. Games that are pending approval will only be visible after logging in.</p>
                <GamesEditableContainer />
            </div>
        );
    }
}

GamesList.propTypes = {
    getMyGames: PropTypes.func.isRequired
};

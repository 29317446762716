import { connect } from 'react-redux';
import PlayerDeleteForm from '../components/forms/playerDeleteForm';
import {withRouter} from 'react-router-dom'
import { deletePlayer } from '../actions';


const mapDispatchToProps = ({
    onSubmit: deletePlayer
});

const buildPlayer = (state, ownProps) => {
    const player = state.players.find(x => x.id === ownProps.match.params.playerId);
    player.gameId = ownProps.match.params.id;
    return player;
}

const mapStateToProps = (state, ownProps) => ({
    initialValues: buildPlayer(state, ownProps),
    players: state.players
});

const PlayerDeleteFormContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerDeleteForm));


export default PlayerDeleteFormContainer;
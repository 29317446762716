import React from 'react';
import { Field } from 'redux-form';
import renderStatement from './renderStatement';

const renderStatements = ({ fields, explanation, meta: { error, submitFailed } }) =>
    <ul className="list-group">
        {fields.map((statement, index) =>
            <li className="list-group-item" key={index}>
                <Field
                    name={statement}
                    type="text"
                    component={renderStatement}
                    label={`${explanation} #${index + 1}`}
                    onClick={() => fields.remove(index)}
                    index={index}
                />
            </li>
        )}
        {submitFailed &&
            error &&
            <li className="error">
                {error}
            </li>}
        <li className="list-group-item" >
            <button type="button" className="btn btn-success" onClick={() => fields.push()}>Add New </button>
        </li>
    </ul>

export default renderStatements;
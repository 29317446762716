import React from 'react';
import PropTypes from 'prop-types';
import GameAdminLink from './GameAdminLink';
import { Link } from 'react-router-dom';


export class GamesAdminList extends React.Component {


	render() {
		const { games, ui } = this.props;
		let p = <div className="alert alert-info">...loading games.</div>;
		if (!ui.loading) {
			if (games && games.length > 0) {
				p = <ul className="list-group">
					{games.map(function (item, i) {
						return <GameAdminLink name={item.name}
							description={item.description}
							id={item.id}
							key={i} />;
					}
					)}
				</ul>;
			} else {
				p = <Link to="/game/create">Create your first game</Link>
			}
		}
		return p;
	}
}


GamesAdminList.propTypes = {
	games: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		approved: PropTypes.bool
	}).isRequired).isRequired,
	ui: PropTypes.object.isRequired
}

export default GamesAdminList;
import React from 'react';
import './about.css';

export const About = () => (
    <div>
        <h1>Snakes and Ladders:</h1>
        <p>This a just a bit of fun.</p>
        <p>Anyone can create their own games, and have their friends going up and down ladders and snakes. All
            you need is a google email address/login. </p>
        <p>
            Games have to be approved to be publicly visible, but
            you can see (and play) your unapproved games when you log in.
            </p>
        <h2>
            Technologies
        </h2>
        <p>The underlying technologies are:</p>
        <h3>Hosting/Deployment</h3>
        <div className="nerd"> 
            <h4>
                Hosting
                 </h4>
            <p>
                Google App Engine Standard (NodeJs beta)
            </p>
            <p>
                NodeJs/Express for API, with Firebase for authentication</p>
            <h4>
                Data Storage
             </h4>
            <p>
                The game and player objects are stored inside Google Cloud DataStore.
             </p>
        </div>
        <h3>Front End</h3>
        <div className="nerd">
            <h4>
                The Game
                        </h4>
            <p>
                Is written in JavaScript, uses jQuery for DOM interaction, and features canvas and audio elements.
                            </p>
            <h4>
                The SPA engine
                            </h4>
            <p>
                Is React, with redux, integrated with a component
                (the game itself) which interacts directly with the DOM.
                        </p>
            <h4>
                Progressive Web Application
                            </h4>
            <p>
                The site is a PWA. It uses a manifest and service worker to cache all the static assets -
                extending React's service worker by ejecting create-react-app and adding additional
                non-react resources associated with the game.
                                </p>
            <p>
                IndexedDB is used to cache games and players, so that any game that has been played
                online can also be played offline.
                                    </p>
            <h4>
                Authentication
                            </h4>
            <p>
                Firebase - free, and easy to integrate. 
                        </p>
        </div>
        <p>
            &nbsp;
                </p>
    </div>

);




import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import renderInlineField from './renderInlineField';
import renderStatements from './renderStatements';
import DropWrapper from '../dropWrapper';

//handling dropped image
const handleImageChosen = (e, target) => {
	let size = 50;
	let newHeight;
	let newWidth;
	let files = e.target.files || e.dataTransfer.files;
	if (files && files.length > 0) {
		let f = files[0];
		let reader = new FileReader();
		reader.onload = (function (theFile) {
			return function (e2) {
				let image = new Image();
				image.onload = function () {
					newHeight = this.height;
					newWidth = this.width;
					if (this.height > size) {
						newHeight = size;
						newWidth = (newHeight * this.width) / this.height;
					}
					if (newWidth > size) {
						let oldWidth = newWidth;
						newWidth = size;
						newHeight = (newWidth * newHeight) / oldWidth;
					}
					let canvas = document.createElement("canvas");
					let ctx = canvas.getContext("2d");
					canvas.width = newWidth;
					canvas.height = newHeight;
                    ctx.drawImage(image, 0, 0, newWidth, newHeight);
					//target.initialValues.image = canvas.toDataURL(f.type);
                    target.change("image", canvas.toDataURL(f.type));
				};
				image.src = e2.target.result;
			};
		})(f);
		reader.readAsDataURL(f);
	}
}


//Client side validation
const validateLoop = (values, exp) => {
    const arr = [];
    values.forEach((thing, i) => {
        if (!thing || !thing.length) {
            arr[i] = 'Must provide a ' + exp;
        }
        else if (thing.length < 3) {
            arr[i] = 'Must be at least 3 characters';
        }
    });
    return arr;
}


const validate = values => {
    const errors = {};

    if (!values.name || values.name.trim() === '') {
        errors.name = 'Enter a name';
    }
    else if (values.name.length > 100 || values.name.length < 3) {
        errors.name = 'Name must be between 3 and 100 characters';
    }
    if (!values.HR || !values.HR.length) {
        errors.HR = { _error: 'Must be at least one reason for going up a ladder' }
    }
    else {
        const hRArrayErrors = validateLoop(values.HR, "reason for going up a ladder");
        const sRArrayErrors = validateLoop(values.SR, "reason for going down a snake");
        const sSArrayErrors = validateLoop(values.SS, "statement when going down a snake");
        const hSArrayErrors = validateLoop(values.HS, "statement when going up a ladder")
        if (hRArrayErrors.length) {
            errors.HR = hRArrayErrors
        }
        if (sRArrayErrors.length) {
            errors.SR = sRArrayErrors
        }
       
        if (sSArrayErrors.length) {
            errors.SS = sSArrayErrors
        }
        if (hSArrayErrors.length) {
            errors.HS = hSArrayErrors
        }
    }
    return errors;
}

const PlayerForm = props => {
    const { handleSubmit, submitting, initialValues, image } = props;
    const del = initialValues.name ? <p><Link to={`/player/delete/${initialValues.id}`}> Delete this player</Link></p> : '';
    const sub = submitting ? <div className="alert alert-info">saving your changes</div> : '';

    return <form onSubmit={handleSubmit}>
        {del}
        <div className="container-fluid">
            <div className="row vertical-align">
                <div className="col-xs-3">
                    <Field
                        name="name"
                        type="text"
                        component={renderInlineField}
                        label="Player Name*" />
                </div>
                <div className="col-xs-9">
                <DropWrapper dropped={handleImageChosen} dropAction={props}>
                    <img src={image} alt={initialValues.name} title={initialValues.name} />
				</DropWrapper>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="row">
                        <h3>Reason for going up a ladder:</h3>
                    </div>
                    <div className="row">
                        <FieldArray name="HR" explanation="Reason" component={renderStatements} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="row">
                        <h3>What I say when I go up a ladder:</h3>
                    </div>
                    <div className="row">
                        <FieldArray name="HS" explanation="Statement" component={renderStatements} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="row">
                        <h3>Reason for going down a snake:</h3>
                    </div>
                    <div className="row">
                        <FieldArray name="SR" explanation="Reason" component={renderStatements} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="row">
                        <h3>What I say when I go down a snake:</h3>
                    </div>
                    <div className="row">
                        <FieldArray name="SS" explanation="Statement" component={renderStatements} />
                    </div>
                </div>
            </div>
            <div>
                <button type="submit" className="btn btn-default btn-primary" disabled={submitting}>Save Changes</button>
            </div>
            {sub}
            <br /><br />
        </div>
    </form>;

}

PlayerForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'player',
    validate,
    // asyncValidate
    onSubmitSuccess: (result, despatch, props) => {
        props.history.push('/mygames');
    }
})(PlayerForm);

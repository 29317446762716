import { connect } from 'react-redux';
import GameForm from '../components/forms/gameForm';
import {withRouter} from 'react-router-dom';
import { addGame } from '../actions';


const mapDispatchToProps = ({
    onSubmit: addGame
})

const GameNewFormContainer = withRouter(connect(null, mapDispatchToProps)(GameForm));


export default GameNewFormContainer;
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import renderField from './renderField';

//Client side validation
const validate = values => {
    const errors = {};

    if (!values.name || values.name.trim() === '') {
        errors.name = 'Enter a name';
    }
    else if (values.name.length > 256 || values.name.length < 3) {
        errors.name = 'Name must be between 3 and 256 characters';
    }

    if (!values.description || values.description.trim() === '') {
        errors.description = 'Enter a description';
    }
    else if (values.description.length > 256 || values.description.length < 10) {
        errors.description = 'Description must be between 10 and 256 characters';
    }

    return errors;
}


const GameForm = props => {
    const { handleSubmit, submitting, initialValues } = props;
    const del = initialValues ? <p><Link to={`/game/delete/${initialValues.id}`}> Delete this game</Link></p> :  '';
    const sub = submitting ? <div className="alert alert-info">saving your changes</div> : '';

    return <form onSubmit={handleSubmit}>
        {del}
        <Field
            name="name"
            type="text"
            component={renderField}
            label="Game Name*" />
        <Field
            name="description"
            type="text"
            component={renderField}
            label="Description*" />
        <button type="submit" className="btn btn-default btn-primary" disabled={submitting}>Submit</button>
        {sub}
        <br /><br />
    </form>;

}

GameForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'game',
    validate,
    // asyncValidate
    onSubmitSuccess: (result, despatch, props) => {
        props.history.push('/admin');
    }
})(GameForm);

import { connect } from 'react-redux';
import PlayerForm from '../components/forms/playerForm';
import { withRouter } from 'react-router-dom'
import { updatePlayer } from '../actions';
import { formValueSelector } from 'redux-form';

//shape data to fit field array
const modfiedData = (player, gameId) => {
    return {
        gameId: gameId,
        id: player.id,
        name: player.name,
        image: player.image,
        HR: player.details.HR.map(function (a) { return a.R; }),
        HS: player.details.HS.map(function (a) { return a.S; }),
        SR: player.details.SR.map(function (a) { return a.R; }),
        SS: player.details.SS.map(function (a) { return a.S; })
    }
}

const mapDispatchToProps = ({
    onSubmit: updatePlayer
});

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('player')
    const player = state.players.find(x => x.id ===  ownProps.match.params.playerId);
    return {
        initialValues: modfiedData(player, ownProps.match.params.id ),
        image: selector(state, 'image')
    }
};

const PlayerEditFormContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PlayerForm));


export default PlayerEditFormContainer;
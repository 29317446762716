import React from 'react';
import PlayerNewFormContainer from '../../containers/playerNewFormContainer';

const PlayerCreate = () => {
    return (
        <div>
            <h2>Create new player:</h2>
            <p>NB. No changes are final until Save Changes button is clicked.</p>
            <PlayerNewFormContainer />
        </div>
    );
}

export default PlayerCreate;


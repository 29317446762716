const UPDATE = 'lalaladders/notification/UPDATE';
// const REMOVE = 'lalaladders/games/REMOVE';

export const notifications = (state = [], action) => {
    switch (action.type) {
        case UPDATE:
            return state.indexOf(action.id) === -1 ? [
                    action.id, ...state
                ] :
                state;
        default:
            return state
    }
}
// Action Creators

export const update = id => {
    return {
        type: UPDATE,
        id
    };
}
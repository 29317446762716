import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const GameDeleteForm = props => {
    const { handleSubmit, submitting, initialValues, games } = props;
    const sub = submitting ? <p>Deleting game...</p> : '';
    let content = <Redirect to="/admin" push />;
    if (games.length > 0) {
        content = <form onSubmit={handleSubmit}>
            <h2>Confirm delete {initialValues.name}</h2>
            <button type="submit" className="btn btn-default btn-primary" disabled={submitting}>Submit</button>
            <br /><br />
            {sub}
        </form>;
    }

    return <div>
        {content}
    </div>;
}

GameDeleteForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'gameDelete',
})(GameDeleteForm);

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default class GameLink extends React.Component {

	shouldComponentUpdate(nextProps) {
		return (this.props.name !== nextProps.name);
	}

	render() {
			return (<li className="list-group-item" >
			<Link to={`/play/${this.props.id}`}>
				{this.props.name} - {this.props.description}
			</Link>
		</li>
		);
	}
}


GameLink.defaultProps = {
	name: 'unknown',
	description: 'unknown'
};

GameLink.propTypes = {
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired
};


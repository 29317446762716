import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const PlayerDeleteForm = props => {
    const { handleSubmit, submitting, initialValues, players } = props;
    const sub = submitting ? <p>Deleting player...</p> : '';
    let content = <Redirect to="/admin" push />;
    if (players.length > 0) {
        content = <form onSubmit={handleSubmit}>
            <h2>Confirm delete {initialValues.name}</h2>
            <button type="submit" className="btn btn-default btn-primary" disabled={submitting}>Submit</button>
            <br /><br />
            {sub}
        </form>;
    }

    return <div>
        {content}
    </div>;
}

PlayerDeleteForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'playerDelete',
    onSubmitSuccess: (result, despatch, props) => {
        props.history.push('/mygames');
    }
})(PlayerDeleteForm);

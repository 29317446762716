import { connect } from 'react-redux';
import PlayersAdmin from '../components/playersadmin/PlayersAdmin';
import { receivePlayers } from '../actions';

const mapDispatchToProps = ({
    receivePlayers
})

const PlayersAdminContainer = connect(null, mapDispatchToProps)(PlayersAdmin);

export default PlayersAdminContainer;
import React from 'react';
import PropTypes from 'prop-types';

const allow = (e) => {
    e.stopPropagation();
    e.preventDefault();
}

const handleDrop = (e, dropped, dropAction) => {
    allow(e);
    dropped(e, dropAction);
}

const DropWrapper = (props) => (<div className="fred" onDragOver={(e) => { allow(e) } }
    onDragEnter={(e) => { allow(e) } }
    onDragLeave={(e) => { allow(e) } }
    onDrop={(e) => { handleDrop(e, props.dropped, props.dropAction) } }>
    {props.children}
</div>)


DropWrapper.propTypes = { dropped: PropTypes.func.isRequired, 
                         dropAction: PropTypes.object.isRequired};

export default DropWrapper;
import { connect } from 'react-redux';
import Players from '../components/players/Players';

const mapStateToProps = (state) => ({
    players: state.players,
    ui: state.ui
})


const PlayersContainer = connect(mapStateToProps)(Players);

export default PlayersContainer;
import { load as loadPlayer } from './playerReducer';

const LOAD = 'lalaladders/players/LOAD';
const ADD = 'lalaladders/players/ADD';
const UPDATE = 'lalaladders/players/UPDATE';
const REMOVE = 'lalaladders/players/REMOVE';

export const players = (state = [], action) => {
    switch (action.type) {
        case LOAD:
            return action.players;
        case ADD:
            return [
                loadPlayer(action), ...state
            ];
        case UPDATE:
            const obj = state.find(x => x.id === action.player.id);
            let index = state.indexOf(obj);
            return [
                ...state.slice(0, index), action.player, ...state.slice(index + 1)
            ];
        default:
            return state;
    }
}


// Action Creators
export const load = players => {
    return { type: LOAD, players };
}

export const add = player => {
    return { type: ADD, player };
}

export const update = player => {
    return { type: UPDATE, player };
}

export const remove = player => {
    return { type: REMOVE, player };
}
import React from 'react';
import PropTypes from 'prop-types';
import GamesContainer from '../containers/gamesContainer';
import {condition} from '../store/uiReducer';

export class Home extends React.Component {

    componentDidMount() {
        this.props.getGames();
    }

    render() {
        const { games, ui } = this.props;
        let p = '';
        switch(ui.condition) {
            case condition.loading:
                p = <div className="alert alert-info">...checking for games.</div>;
                break;
            case condition.offline:
                p = <div className="alert alert-danger">Sorry, the games list is not available offline.
                You have to visit it at least once while online in order to make it available offline.</div>;
                break;
            case condition.updated:
                p = <div className="alert alert-info">The game list has been updated on the server. 
                    Refresh to get the latest version.</div>;
                break;    
            default:
                p = '';
        }

        let g = '';
        if (games && games.length > 0) {
            g = <GamesContainer />;
        }
        return (
            <div>
                <h2>Games:</h2>
                {p}
                {g}
            </div>
        );
    }
}

Home.propTypes = {
    getGames: PropTypes.func.isRequired,
    games: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        approved: PropTypes.bool
    }).isRequired)
};

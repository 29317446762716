import { connect } from 'react-redux';
import Games from '../components/games/Games';


const mapStateToProps = (state) => ({
    games: state.games.filter(x => x.approved)
});

const GamesContainer = connect(mapStateToProps)(Games);

export default GamesContainer;
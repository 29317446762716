const LOAD = 'lalaladders/game/LOAD';
// const CREATE = 'lalaladders/game/CREATE';
// const UPDATE = 'lalaladders/game/UPDATE';
// const REMOVE = 'lalaladders/game/REMOVE';


export const game = (state = { name: '', description: '', id: 0 }, action) => {
    switch (action.type) {
        case LOAD:
            return action.game
        default:
            return state
    }
}


// Action Creators
export const load = game => {
    return { type: LOAD, game };
}

// export const create = game => {
//     return { type: CREATE, game };
// }

// export const update = game => {
//     return { type: UPDATE, game };
// }

// export const remove = game => {
//     return { type: REMOVE, game };
// }
const LOADING = 'lalaladders/ui/LOADING';
const LOADED = 'lalaladders/ui/LOADED';
const OFFLINE = 'lalaladders/ui/OFFLINE';
const UPDATED = 'lalaladders/ui/UPDATED';

export const condition = Object.freeze({
    loaded:0,
    loading:1,
    offline:2,
    updated:3
});

let initialUi = {
    condition: condition.loaded
};

export const ui = (state = initialUi, action) => {
    switch (action.type) {
        case LOADING:
            return {
                condition: condition.loading
            };
        case LOADED:
            return {
                condition: condition.loaded
            };
        case OFFLINE:
            return {
              
                condition: condition.offline
            };
        case UPDATED:
            return {
                condition: condition.updated
            };
        default:
            return state
    }
}

// Action Creators
export const loading = () => {
    return {
        type: LOADING
    };
}

export const loaded = () => {
    return {
        type: LOADED
    };
}

export const offline = () => {
    return {
        type: OFFLINE
    };
}

export const updated = () => {
    return {
        type: UPDATED
    };
}
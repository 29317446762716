import React from 'react';
import PropTypes from 'prop-types';
import PlayersAdminListContainer from '../../containers/playersAdminListContainer';

export default class PlayersAdmin extends React.Component {

    componentDidMount() {
        this.props.receivePlayers(this.props.match.params.id);
    }

    render() {
        return (
            <div>
                <h2>Manage players:</h2>
                <PlayersAdminListContainer />
            </div>
        );
    }
}


PlayersAdmin.propTypes = {
    receivePlayers: PropTypes.func.isRequired
};

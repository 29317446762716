import React from 'react';
import GameEditFormContainer from '../../containers/gameEditFormContainer';

const GameEdit = () => {
    return (
        <div>
            <h2>Edit existing game:</h2>
            <GameEditFormContainer />
        </div>
    );
}

export default GameEdit;


import { connect } from 'react-redux';
import {GamesList} from '../components/gamesadmin/GamesList';
import { getMyGames } from '../actions';


const mapDispatchToProps = ({
    getMyGames
})

const GamesAdminContainer = connect(null, mapDispatchToProps)(GamesList);


export default GamesAdminContainer;
import React from 'react';
import PropTypes from 'prop-types';

export default class PlayerListItem extends React.Component {

	shouldComponentUpdate(nextProps, nextState) {
		if(this.props.player && nextProps.player) {
			return (this.props.player.id !== nextProps.player.id);
		}
		return true;
	}

    render() {	
        return  (<img src={this.props.player.image} alt={this.props.player.name}  data-player={JSON.stringify(this.props.player.details)}  className="playerImage" />);
    }

}

PlayerListItem.propTypes = { player: PropTypes.object.isRequired };


import React from 'react';
import PropTypes from 'prop-types';
import PlayerListItem from './PlayerListItem';
import constants from '../../constants';
import { Link } from 'react-router-dom';
import { condition } from '../../store/uiReducer';
import { withRouter } from 'react-router';

class Players extends React.Component {


    setUpGame() {
        window.game = window.gameBuilder.build();
        const back = new Image();
        back.src = constants.back;
        back.onload = function () {
            window.game.Board = back;
            window.game.BeginGame();
        };
    }

    componentDidUpdate() {
        if (this.props.players && this.props.players.length > 0 && window.game == null) {
            this.setUpGame();
        }
    }

    render() {
        let { players, ui } = this.props;

        let w = '';
        switch (ui.condition) {
            case condition.loading:
                w = <div className="alert alert-info">...loading players for this game.</div>;
                break;
            case condition.offline:
                w = <div className="alert alert-danger">Sorry, that game is not available offline.
                You have to visit a game at least once while online in order to make it available offline.</div>
                break;
            case condition.updated:
                w = <div className="alert alert-info">This game has been updated on the server.
                    Refresh to get the latest version.</div>;
                break;
            default:
                w = '';
        }
        let pl = ''
        if (players && players.length > 0) {
            pl = players.map(function (p, i) {
                return <PlayerListItem player={p} key={i} />;
            })
        }

        return (<div>
            <p>Play this game, or <Link to="/games">pick a different game here</Link></p>

            <div className="playersContainer" ref={(div) => { this.gameDiv = div }}>
                {w}
                {pl}
            </div>
        </div>);
    }


    componentDidMount() {

        if (this.props.match.params.id) {
            window.game = null;
        }
        else {
            this.setUpGame();
        }


    }
}

export default withRouter(Players);

Players.propTypes = {
    players: PropTypes.array
};



import { connect } from 'react-redux';
import GamesAdminList from '../components/gamesadmin/GamesAdminList';


const mapStateToProps = (state) => ({
    games: state.games,
    ui: state.ui
});

const GamesEditableContainer = connect(mapStateToProps)(GamesAdminList);

export default GamesEditableContainer;
const url = process.env.REACT_APP_API;
const apiKey = process.env.REACT_APP_APPIGEE_KEY;  

const headers = () => {
    return {
        headers: {
            apikey: apiKey
        }
    }
}

const authorizedHeaders = (token) => {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            apikey: apiKey
        }
    };
}

const editHeaders = (token) => {
    return {
        Authorization: `Bearer ${token}`,
        apikey: apiKey,
        'accept': 'application/json',
        'content-type': 'application/json',
    };

}

const deleteHeaders = (token) => {
    return {
        Authorization: `Bearer ${token}`,
        apikey: apiKey
    };

}

const entityPost = (token, entity) => {
    return {
        method: 'post',
        headers: editHeaders(token),
        body: JSON.stringify(entity)
    }
}

const entityPut = (token, entity) => {
    return {
        method: 'put',
        headers: editHeaders(token),
        body: JSON.stringify(entity)
    }
}

const entityDelete = (token) => {
    return {
        method: 'delete',
        headers: deleteHeaders(token)
    }
}

const getFetch = (url, token) => {
    if (token) {
        return fetch(url, authorizedHeaders(token));
    } else {
        return fetch(url, headers());
    }
}

const validateResponse = (response) => {
    if (!response.ok) {
        throw Error(response.status);
    }
    return response;
}

const readResponseAsJSON = (response) => {
    return response.json();
}

const logError = (error) => {
    if (error !== 404) {
        throw error(error);
    }
    else {
        console.log("failed to get resource, error: " & error);
    }
}

//end utility methods

export const fetchAllGames = () => {
    return getFetch(url + "games")
        .then(validateResponse)
        .then(readResponseAsJSON)
        .then((games) => {
            return games;
        }).catch(logError);
}


export const getPlayers = (id) => {
    return fetch(url + "players/" + id, headers())
        .then(validateResponse)
        .then(readResponseAsJSON)
        .then((players) => {
            return players;
        }).catch(logError);
}

export const getMyGames = (token) => {
    return fetch(url + "games/mine", authorizedHeaders(token)).then(function (response) {
        return response.json();
    });
}

export const addGame = (game, token) => {
    return fetch(url + "game", entityPost(token, game)).then(function (response) {
        return response.json();
    });
}

export const updateGame = (game, token) => {
    return fetch(url + "game", entityPut(token, game)).then(function (response) {
        return response.json();
    });
}

export const deleteGame = (token, id) => {
    return fetch(url + "game/" + id, entityDelete(token)).then(function (response) {
        return response.json();
    });
}


export const addPlayer = (player, token) => {
    return fetch(url + "player", entityPost(token, player)).then(function (response) {
        return response.json();
    });
}

export const updatePlayer = (player, token) => {
    return fetch(url + "player", entityPut(token, player)).then(function (response) {
        return response.json();
    });
}


export const deletePlayer = (token, gameId, playerId) => {
    return fetch(url + "player/" + gameId + "/" + playerId, entityDelete(token)).then(function (response) {
        return response.json();
    });
}
import React from 'react';
import PropTypes from 'prop-types';
import GameLink from './GameLink';


const Games = ({ games }) => (
	<ul className="list-group">
		{games.map(function (item, i) {
			return <GameLink name={item.name}
				description={item.description}
				id={item.id}
				key={i} />;
		}
		)}
	</ul>
)


Games.propTypes = {
	games: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired
	}).isRequired).isRequired
}

export default Games;